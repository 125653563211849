import React from "react"
import { Link } from "gatsby"
import classNames from 'classnames'
import fb from "../images/menu/icon_fb.svg"
import insta from "../images/menu/icon_insta.svg"
import mail from "../images/menu/icon_mail.svg"
import web from "../images/menu/icon_web.svg"
import bg from "../images/menu/menu_bg.jpg"
import bg_pc from "../images/menu/menu_bg_pc.jpg"

class Menu extends React.Component {

  render() {
    var menuClass = classNames('m_menu', {
        'on' : this.props.menuActive
    })
    return (
     <div className={menuClass} id="m_menu">
       <div className="m_menu_bg">
          <picture>
            <source media="(min-width:980px)" srcSet={bg_pc} />
            <img src={bg} alt="" />
          </picture>
       </div>
       <div className="m_menu_inner">
         <div className="m_menu_head">
            <Link className="m_menu_head_text" to={`/`}>
             <p className="m_menu_head_ttl">HARUHI FUJII</p>
             <p className="m_menu_head_subttl ja">藤井 春日</p>
           </Link>
         </div>
         <nav className="m_menu_nav">
           <ul className="m_menu_nav_list">
              <li className="m_menu_nav_list_li"><Link to={`/`}>Home</Link></li>
              <li className="m_menu_nav_list_li"><Link to={`/category/Exhibitions`}>Exhibitions</Link></li>
              <li className="m_menu_nav_list_li"><Link to={`/category/Photo%20books`}>Photo books</Link></li>
              <li className="m_menu_nav_list_li"><Link to={`/photo/works`}>Works</Link></li>
              <li className="m_menu_nav_list_li"><Link to={`/profile`}>Profile</Link></li>
              <li className="m_menu_nav_list_li"><Link to={`/information`}>Information</Link></li>
           </ul>
         </nav>
         <ul className="m_menu_sns">
            <li className="fb"><a aria-label="Facebook" href="https://www.facebook.com/haruhi.fujii.1" target="_blank" rel="noopener noreferrer"><img src={fb} alt="Facebook" /></a></li>
            <li className="insta"><a aria-label="Instagram" href="https://www.instagram.com/haruhifujii/" target="_blank" rel="noopener noreferrer"><img src={insta} alt="Instagram" /></a></li>
            <li className="mail"><a aria-label="Mail" href="mailto:&#104;&#102;&#64;&#102;&#117;&#106;&#105;&#105;&#104;&#97;&#114;&#117;&#104;&#105;&#46;&#106;&#112;"><img src={mail} alt="Mail" /></a></li>
         </ul>
         <div className="m_menu_bottom">
           <p className="m_menu_bottom_ttl">草枕社</p>
           <p className="m_menu_bottom_icon web"><a aria-label="Website" href="http://www.kusamakurasha.com/" target="_blank" rel="noopener noreferrer"><img src={web} alt="website" /></a></p>
           <p className="m_menu_bottom_icon fb"><a aria-label="Facebook" href="https://www.facebook.com/kusamakurasha" target="_blank" rel="noopener noreferrer"><img src={fb} alt="facebook" /></a></p>
         </div>
       </div>
     </div>
    )
  }
}

export default Menu