/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import Header from "./header"
import PropTypes from "prop-types"
import "../styles/style.scss"
import posed from "react-pose"
import { Link } from "gatsby"


const Fade = posed.div({
  enter: {
    opacity: 1,
    delay:500,
    transition: { duration: 900 },
  },
  exit: {
    opacity: 0,
    delay: 0,
    transition: { duration:400 },
  },
})


const Layout = ({ children }) => {
    return (
      <>
        <div className="outer" id="outer">
            <Header />
            <Fade>
              <div className="wrapper" id="wrapper">
                <main>{children}</main>
              </div>
              <footer className="m_foot">
                <div className="m_foot_inner inner">
                  <p className="m_foot_home"><Link to={`/`}>Home</Link></p>
                  <p className="m_foot_copy en">&copy; Haruhi Fujii </p>
                </div>
              </footer>
            </Fade>
        </div>
      </>
    )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
