import React from "react"
import Menu from "./menu"
import classNames from 'classnames'


class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {menuActive: false}
  }
  toggle(e) {
    e.stopPropagation();
    this.setState({menuActive: !this.state.menuActive});
  }
  componentDidMount() {
    var self = this;
    var _touch = (( window.ontouchstart!==null ) ? 'click':'touchend');
    this.close = function(e){
      if (!e.target.closest("#m_menu") && !e.target.closest("#menu_btn")) {
        self.setState({menuActive: false});
      }
      if(e.target.closest(".m_menu_nav_list_li") || e.target.closest(".m_menu_head_text")){
        setTimeout(function(){
          self.setState({menuActive: false});
        },100)
      }
    }
    document.addEventListener(_touch,this.close);
  }
  componentWillUnmount() {
    var _touch = (( window.ontouchstart!==null ) ? 'click':'touchend');
    document.removeEventListener(_touch, this.close); // Unmount時に外してあげる
  }



  render() {
    var menuClass = classNames('m_head_menu_btn', {
        'on' : this.state.menuActive
    })
    return (
      <header className="m_head">
        <div className="m_head_inner">
          <div className={menuClass} id="menu_btn" onClick={this.toggle.bind(this)}>
            <div className="m_head_menu_btn_inner">
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
        <Menu menuActive={this.state.menuActive}/>
      </header>
    )
  }
}

export default Header
